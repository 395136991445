import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../core/store";
import {
  getActiveOrProposedTeamChipName,
  getChipsById,
  getPotentialChips,
} from "../../../core/store/chips/reducers";
import { chipActivationHandle } from "../../../core/store/chips/thunks";
import { IPotentialChip } from "../../../core/store/chips/types";
import { getEntry } from "../../../core/store/entries/reducers";
import {
  getCurrentEvent,
  getEventsById,
} from "../../../core/store/events/reducers";
import { getMyPicksProposed } from "../../../core/store/my-team/reducers";
import { getPlayerData } from "../../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../../core/store/player/types";
import { getTransferState } from "../../../core/store/squad/reducers";
import { formatRawAsLocal } from "../../../core/utils/datetime";
import { getChipName } from "../../../utils/chips";
import ChipButton from "../../Chips/ChipButton";
import ChipDialog, {
  ChipDialogBody,
  ChipDialogHeader,
} from "../../Chips/ChipDialog/ChipDialog";
import Copy from "../../Copy";
import DialogManager from "../../DialogManager";
import ChipCard from "../ChipCard";
import {
  ChipButtonWrap,
  ChipCardLink,
  ChipLabel,
  StyledChipList,
} from "./styles";
import { IChipList } from "./types";

const ChipList: React.FC<IChipList> = ({ chipsShown = "all" }) => {
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const player = useSelector(
    (state: RootState) => getPlayerData(state) as ILoggedInPlayer
  );
  const entry = useSelector((state: RootState) =>
    getEntry(state, player.entry)
  );
  const chipInPlayName = useSelector(getActiveOrProposedTeamChipName);
  const allChips = useSelector(getChipsById);
  const potentialChips = useSelector(getPotentialChips);
  const eventsById = useSelector(getEventsById);
  const picks = useSelector(getMyPicksProposed);
  const transferState = useSelector(getTransferState);
  const now = useSelector(getCurrentEvent);

  const dispatch = useDispatch();

  const activateChip = (name: string) => dispatch(chipActivationHandle(name));
  const deactivateChip = () => dispatch(chipActivationHandle(null));

  const handlePlayClick = (chipName: string, handleHide: () => void) => {
    let askConfirmation = false;
    if (chipName === "freehit") {
      askConfirmation = transferState ? transferState.made > 0 : false;
    } else if (chipName === "wildcard") {
      askConfirmation =
        transferState && transferState.limit ? transferState.made > 1 : false;
    }

    if (!askConfirmation) {
      activateChip(chipName);
      handleHide();
      setShowModalConfirmation(false);
    } else {
      setShowModalConfirmation(true);
    }
  };

  const handleConfirmClick = (chipName: string) => {
    setShowModalConfirmation(false);
    activateChip(chipName);
  };

  const handleCancelClick = () => {
    setShowModalConfirmation(false);
    deactivateChip();
  };

  if (!entry || !picks.length) {
    return null;
  }

  const getSortedChips = () => {
    const chipsByName = potentialChips.reduce<Record<string, IPotentialChip>>(
      (acc, c) => {
        acc[c.name] = c;
        return acc;
      },
      {}
    );
    const sortedChipNames = [
      ...(chipsShown === "all" ? ["bboost", "3xc"] : []),
      "wildcard",
      "freehit",
      "manager",
    ];

    // filter because transfers chips aren't returned pre-rollover
    return sortedChipNames
      .filter((name) => chipsByName[name])
      .map((name) => chipsByName[name]);
  };

  const renderTransferChipAction = (
    chip: IPotentialChip,
    id: string,
    handleHide: () => void
  ) => {
    if (!entry || chip.name === "managerplaceholder") {
      return null;
    }

    switch (chip.status_for_entry) {
      case "available":
        return (
          <ChipButtonWrap>
            {showModalConfirmation ? (
              <>
                <ChipButton
                  variant="play"
                  onClick={() => {
                    handleConfirmClick(chip.name);
                    handleHide();
                  }}
                  aria-describedby={id}
                >
                  Confirm
                </ChipButton>

                <ChipButton
                  variant="cancel"
                  onClick={() => {
                    handleCancelClick();
                    handleHide();
                  }}
                  aria-describedby={id}
                >
                  Cancel
                </ChipButton>
              </>
            ) : (
              <ChipButton
                variant="play"
                onClick={() => {
                  handlePlayClick(chip.name, handleHide);
                }}
                aria-describedby={id}
              >
                Play Chip
              </ChipButton>
            )}
          </ChipButtonWrap>
        );
      case "unavailable":
        return <ChipLabel>Unavailable</ChipLabel>;
      case "active":
        if (chip.is_pending) {
          return (
            <ChipButton
              variant="cancel"
              onClick={() => {
                handleHide();
                deactivateChip();
              }}
              aria-describedby={id}
            >
              Cancel
            </ChipButton>
          );
        }
        return <ChipLabel>Active</ChipLabel>;
      default:
        return "";
    }
  };

  const renderChipAction = (
    c: IPotentialChip,
    id: string,
    handleHide: () => void
  ) => {
    if (!entry) {
      return null;
    }

    switch (c.status_for_entry) {
      case "available":
        return (
          <ChipButton
            variant="play"
            onClick={() => {
              handleHide();
              activateChip(c.name);
            }}
            aria-describedby={id}
          >
            Play Chip
          </ChipButton>
        );

      case "unavailable":
        return <ChipLabel>Unavailable</ChipLabel>;
      case "proposed":
        return (
          <ChipButton
            variant="cancel"
            onClick={() => {
              handleHide();
              deactivateChip();
            }}
            aria-describedby={id}
          >
            Cancel
          </ChipButton>
        );
      case "active":
        return (
          <ChipButton
            variant="cancel"
            onClick={() => {
              handleHide();
              deactivateChip();
            }}
            aria-describedby={id}
          >
            Cancel
          </ChipButton>
        );
      case "cancelled":
        return (
          <ChipButton
            variant="play"
            onClick={() => {
              handleHide();
              activateChip(c.name);
            }}
            disabled={Boolean(chipInPlayName)}
            aria-describedby={id}
          >
            Activate
          </ChipButton>
        );
      default:
        return "";
    }
  };

  const managerChip = Object.values(allChips).find((c) => c.name === "manager");
  const isBeforeManagerChipAvailable =
    now && managerChip && now.id < managerChip.start_event - 1;

  const managerDescription = (
    <Copy>
      <p>
        Add a manager to your team to score points for three consecutive
        gameweeks.
      </p>
      <p>
        The Assistant Manager chip can be used once a season. Your initial
        manager selection will not cost a transfer. Any further manager
        transfers will either cost a free transfer or -4 points if you do not
        have a free transfer available.
      </p>
      <p>
        This chip cannot be cancelled once you activate it and confirm a manager
        transfer.
      </p>
      {isBeforeManagerChipAvailable && (
        <p>
          <strong>
            Available from {eventsById[managerChip!.start_event].name}.
          </strong>
        </p>
      )}
    </Copy>
  );

  const chipDescriptions: { [key: string]: React.ReactNode } = {
    bboost: (
      <Copy>
        <p>
          The points scored by your benched players in a Gameweek will be added
          to your total.
        </p>
        <p>It can be cancelled at anytime before the Gameweek deadline.</p>
      </Copy>
    ),
    "3xc": (
      <Copy>
        <p>
          The points scored by your captain will be tripled instead of doubled
          in a Gameweek.
        </p>
        <p>It can be cancelled at anytime before the Gameweek deadline.</p>
      </Copy>
    ),
    freehit: (
      <Copy>
        {showModalConfirmation ? (
          <p>
            <strong>
              You have already made a transfer this Gameweek so you will not be
              able to cancel this chip once played.
            </strong>
          </p>
        ) : (
          <>
            <p>
              Make unlimited free transfers for a single Gameweek. In the next
              Gameweek, your squad will return to how it was at the start of the
              last Gameweek.
            </p>
            <p>
              This chip cannot be cancelled after you play it, unless you have
              not yet made a transfer this Gameweek.
            </p>
          </>
        )}
      </Copy>
    ),
    manager: managerDescription,
    managerplaceholder: managerDescription,
    wildcard: (
      <Copy>
        {showModalConfirmation ? (
          <p>
            <strong>
              You have already made a transfer this Gameweek so you will not be
              able to cancel this chip once played.
            </strong>
          </p>
        ) : (
          <>
            <p>
              Make unlimited permanent transfers in a Gameweek without incurring
              the usual 4 point cost for each.
            </p>
            <p>
              {`You lose the first Wildcard after the Gameweek 19 deadline, ${formatRawAsLocal(
                eventsById[19].deadline_time
              )}.`}
            </p>
            <p>{`The second Wildcard will be available after ${formatRawAsLocal(
              eventsById[19].deadline_time
            )}.`}</p>
            <p>
              This chip cannot be cancelled if you have made 2 or more transfers
              this Gameweek. Once the chip status has changed from “Pending” to
              “Active”, the activation cannot be cancelled or reversed.
            </p>
          </>
        )}
      </Copy>
    ),
  };

  const managerChipPlaceholder: IPotentialChip = {
    status_for_entry: "available",
    played_by_entry: [],
    name: "managerplaceholder",
    number: 1,
    start_event: 1,
    stop_event: 38,
    chip_type: "transfer",
    is_pending: false,
    id: 5,
  };

  return (
    <StyledChipList $chipsShown={chipsShown}>
      {[
        ...getSortedChips(),
        ...(isBeforeManagerChipAvailable ? [managerChipPlaceholder] : []),
      ].map((c) => {
        const chipId = `ism-${c.name}`;
        return (
          <Fragment key={chipId}>
            {c.status_for_entry === "played" ? (
              <ChipCardLink
                to={`/entry/${entry.id}/event/${c.played_by_entry[0]}`}
              >
                <ChipCard chip={c} title={getChipName(c.name)} />
              </ChipCardLink>
            ) : (
              <DialogManager
                key={chipId}
                render={(showDialog, handleShow, handleHide) => (
                  <>
                    <ChipCard
                      chip={c}
                      onClick={handleShow}
                      title={getChipName(c.name)}
                    />
                    {showDialog && (
                      <ChipDialog closeDialog={handleHide}>
                        <ChipDialogHeader closeDialog={handleHide} chip={c} />
                        <ChipDialogBody
                          title={getChipName(c.name)}
                          chipAction={(c, chipId) =>
                            c.chip_type === "transfer"
                              ? renderTransferChipAction(c, chipId, handleHide)
                              : renderChipAction(c, chipId, handleHide)
                          }
                          chip={c}
                          chipId={chipId}
                        >
                          {chipDescriptions[c.name]}
                        </ChipDialogBody>
                      </ChipDialog>
                    )}
                  </>
                )}
              />
            )}
          </Fragment>
        );
      })}
    </StyledChipList>
  );
};

export { ChipList as ChipListTest };

export default ChipList;
