import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import Copy from "./Copy";
import Footnote from "./Footnote";
import HelmetHead from "./HelmetHead";
import { Main, Wrapper } from "./Layout";
import { Hyperlink } from "./Link";
import PrizesNav from "./prizes/PrizesNav";
import SubHeading from "./SubHeading";
import Title from "./Title";

interface IPropsFromState {
  currentEvent: IEvent | null;
}

type PrizesTypes = IPropsFromState & RouteComponentProps;

const Prizes: React.FC<PrizesTypes> = ({ currentEvent }) => (
  <>
    {currentEvent && (currentEvent.id > 1 || currentEvent.finished) && (
      <Wrapper>
        <Box flex={1} pt={4}>
          <PrizesNav />
        </Box>
      </Wrapper>
    )}
    <Wrapper>
      <HelmetHead
        title="Fantasy Football Game Prizes | Fantasy Premier League"
        description="To find out about Fantasy Premier League prizes, including overall, monthly and weekly prizes, visit the official website."
      />
      <Main>
        <Copy>
          <Title>Prizes</Title>
          <Box mb="3rem">
            <SubHeading>Overall Prizes</SubHeading>
            <h4>1st Prize – 2024/25 Fantasy Premier League Champion</h4>
            <ul>
              <li>
                7-night break in the UK inclusive of two VIP hospitality at two
                2025/26 Premier League matches
              </li>
              <li>
                A selection of experiences at popular UK tourist attractions
              </li>
              <li>Includes travel and 7 nights’ accommodation</li>
              <li>Hublot connected watch</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                Personalised FPL bundle consisting of a rucksack, T-shirt, mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>Runner-up</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>3rd place</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>4th place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>5th place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>6th-10th place</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>11th-20th place</h4>
            <ul>
              <li>Official Premier League Nike Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>

            <h4>2024/25 FPL Cup Winner</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                Personalised FPL bundle consisting of a rucksack, T-shirt,mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>2024/25 FPL Cup Runner-up</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>Noise-cancelling headphones (in-ear OR on-ear)</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
          </Box>

          <Box mb="3rem">
            <SubHeading>Second Chance League</SubHeading>
            <h4>1st Prize</h4>
            <ul>
              <li>
                Two hospitality tickets at one 2025/26 Premier League match
              </li>
              <li>Includes travel and 3 nights’ accommodation</li>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Bluetooth speaker</li>
              <li>
                Personalised FPL bundle consisting of a rucksack, T-shirt, mug,
                thermal bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>Runner-up</h4>
            <ul>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
              <li>Tablet computer</li>
              <li>Bluetooth speaker</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>3rd place</h4>
            <ul>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>Tablet computer</li>
              <li>Bluetooth speaker</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>4th place</h4>
            <ul>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>Bluetooth speaker</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
            <h4>5th place</h4>
            <ul>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
          </Box>

          <Box mb="3rem">
            <SubHeading>Monthly</SubHeading>
            <h4>Manager of the Month</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>Nike jacket</li>
              <li>EA SPORTS FC™ game</li>
              <li>Laptop computer OR SIM-free smartphone</li>
            </ul>
            <h4>Runner-up</h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
            </ul>
            <p>
              Top 20 Managers each month (incl Manager of the Month and
              Runner-up) will receive:
            </p>
            <ul>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad and key ring
              </li>
            </ul>
          </Box>

          <Box mb="3rem">
            <SubHeading>Weekly</SubHeading>
            <h4>
              Manager of the Week
              <sup>
                <Hyperlink href="#ism-prizes-footnote">*</Hyperlink>
              </sup>
            </h4>
            <ul>
              <li>Official Premier League Club T-shirt</li>
              <li>EA SPORTS FC™ game</li>
              <li>
                FPL bundle consisting of a rucksack, T-shirt, mug, thermal
                bottle, stress ball, pen, pad, and key ring
              </li>
            </ul>
            <hr />
            <Footnote id="ism-prizes-footnote">
              <sup>*</sup> To be eligible to win a Manager of the week prize,
              you must have joined the game before the start of the 2024/25
              season or a minimum of two clear Gameweeks before the one in which
              you are top of the weekly leaderboard. Players who have played a
              chip (including Bench Boost, Free Hit, Triple Captain, Wildcard or
              Assistant Manager) will not be eligible to win a Manager of the
              Week prize for the Gameweek that such a chip has been used.
            </Footnote>
          </Box>
        </Copy>
      </Main>
    </Wrapper>
  </>
);

const mapStateToProps = (state: RootState): IPropsFromState => ({
  currentEvent: getCurrentEvent(state),
});

export default connect(mapStateToProps)(Prizes);
