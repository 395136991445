import styled from "styled-components/macro";

export const StyledElementPhoto = styled.img<{
  isPitchUnit?: boolean;
  photoWidth?: string;
}>`
  display: inline-block;
  margin: auto;
  vertical-align: middle;
  width: ${(props) => props.photoWidth || "auto"};
  max-width: 100%;

  ${(props) =>
    props.isPitchUnit &&
    `
    width:36px;

    @media screen and (min-width: 610px) {
      width:52px;
    }

    @media screen and (min-width: 1024px) {
      width:68px;
    }

  `};
`;
