import { size } from "polished";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getActiveChip } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { getElement } from "../../core/store/elements/reducers";
import { getNextEvent } from "../../core/store/events/reducers";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ReactComponent as Remove } from "../../img/icons/remove.svg";
import Badge from "../Badge";
import ElementDialogButton from "../ElementDialogButton";
import { StyledElementFixtureBar } from "../ElementFixtureBar";
import ElementPhoto from "../ElementPhoto";
import ElementShirt from "../ElementShirt";
import ElementTypeLabel from "../ElementTypeLabel";
import FixturesForElement from "../FixturesForElement";
import {
  AddPerson,
  BadgeWrap,
  ElementCost,
  ElementName,
  InfoControl,
  PitchCardButton,
  PitchElementData,
  PitchElementIconsWrap,
  PitchElementWrap,
  RemovedElement,
  StyledPitchElement,
} from "../PitchCard";
import { UnstyledButton, VisuallyHidden } from "../Utils";

const RemoveIcon = styled.span`
  display: block;
  padding: 2px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 3px;
  }
`;

const Cross = styled(Remove)`
  ${size(6)}
  display: block;
  fill: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    ${size(8)}
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(10)}
  }
`;

interface IProps {
  actionMe: () => void;
  elementId: number;
  elementValue: React.ReactNode;
  isRemoved?: boolean;
  renderElementMenu: () => void;
}

const SquadPitchElement: React.FC<IProps> = ({
  actionMe,
  elementId,
  elementValue,
  isRemoved,
  renderElementMenu,
}) => {
  const chip = useSelector(getActiveChip);
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );
  const elementTypesById = useSelector((state: RootState) =>
    getElementTypesById(state, undefined, chip?.id)
  );
  const nextEvent = useSelector(getNextEvent);
  const teamsById = useSelector(getTeamsById);

  if (!element) {
    return null;
  }

  const elementType = elementTypesById[element.element_type];

  const copnr = element.chance_of_playing_next_round;
  const team = teamsById[element.team];

  return (
    <PitchElementWrap>
      {isRemoved ? (
        <PitchCardButton type="button" onClick={renderElementMenu}>
          <RemovedElement>
            <AddPerson title="Removed Player" />
            <ElementTypeLabel elementType={elementType} />
            <PitchElementData>
              <ElementName copnr={element.news ? copnr : null}>
                {element.web_name}
              </ElementName>
              <StyledElementFixtureBar>
                <FixturesForElement
                  eventId={nextEvent?.id}
                  elementId={element.id}
                />
              </StyledElementFixtureBar>
            </PitchElementData>
          </RemovedElement>
        </PitchCardButton>
      ) : (
        <>
          <PitchCardButton type="button" onClick={renderElementMenu}>
            <StyledPitchElement>
              <ElementCost>{elementValue}</ElementCost>
              {element.element_type === 5 ? (
                <ElementPhoto
                  alt={team.name}
                  elementId={element.id}
                  isPitchUnit
                  srcDimensions="110x140"
                />
              ) : (
                <ElementShirt
                  alt={team.name}
                  elementId={element.id}
                  sizes="(min-width: 1024px) 68px, (min-width: 610px) 52px, 36px"
                />
              )}
              <PitchElementData>
                <ElementName copnr={element.news ? copnr : null}>
                  {element.web_name}
                </ElementName>
                <StyledElementFixtureBar>
                  <FixturesForElement
                    eventId={nextEvent?.id}
                    elementId={element.id}
                  />
                </StyledElementFixtureBar>
              </PitchElementData>
            </StyledPitchElement>
          </PitchCardButton>
          <div>
            <PitchElementIconsWrap>
              <UnstyledButton type="button" onClick={actionMe}>
                <RemoveIcon>
                  <Cross />
                </RemoveIcon>
                <VisuallyHidden>Remove player</VisuallyHidden>
              </UnstyledButton>
            </PitchElementIconsWrap>
            <InfoControl>
              <ElementDialogButton elementId={element.id} />
            </InfoControl>
            {element.element_type === 5 && (
              <BadgeWrap>
                <Badge
                  alt=""
                  team={team}
                  sizes="(min-width: 1024px) 16px, 13px"
                />
              </BadgeWrap>
            )}
          </div>
        </>
      )}
    </PitchElementWrap>
  );
};

export default SquadPitchElement;
