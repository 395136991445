import { useSelector } from "react-redux";
import { getElementsById } from "../../core/store/elements/reducers";
import { StyledElementPhoto } from "./styles";
import { IElementPhotoProps } from "./types";

const ElementPhoto = ({
  alt,
  elementId,
  srcDimensions,
  photoWidth,
  isPitchUnit = false,
}: IElementPhotoProps) => {
  const elementsById = useSelector(getElementsById);
  const element = elementsById[elementId];

  const elementPhotoDir = `//resources.premierleague.com/premierleague/photos/players/${srcDimensions}`;
  const missingPhotoFilename = "Photo-Missing";
  const elementPhotoFilename = element.has_temporary_code
    ? missingPhotoFilename
    : `${element.opta_code}`;

  return (
    <StyledElementPhoto
      src={`${elementPhotoDir}/${elementPhotoFilename}.png`}
      alt={alt}
      photoWidth={photoWidth}
      isPitchUnit={isPitchUnit}
      role={alt === "" ? "presentation" : undefined}
      onError={(e) => {
        e.currentTarget.src = `${elementPhotoDir}/${missingPhotoFilename}.png`;
      }}
    />
  );
};

export default ElementPhoto;
